import { graphql } from "gatsby";
import DocumentationPage from "../../layouts/WithSideBarPage/_documentationPage/index";

const EULA = (data) => {
  const { htmlAst, frontmatter } = data.data.markdownRemark;
  return (
    <DocumentationPage
      seo={frontmatter.seo[0]}
      // bannerTitle={frontmatter.bannerTitle}
      sidebarList={frontmatter.sidebarList}
      contents={htmlAst}
    />
  );
};

export default EULA;

export const query = graphql`
  query getEula($path: String! $siteLanguage: String!) {
    markdownRemark(frontmatter: { path: { eq: $path }, language: { eq: $siteLanguage } }) {
      htmlAst
      frontmatter {
        seo {
          link
          title
          noForRobots
        }
        bannerTitle
        sidebarList {
          description
          to
        }
      }
    }
  }
`;
